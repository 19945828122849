import React from "react"
import { Box } from "theme-ui"
import GoogleMapReact from "google-map-react"

const GOOGLE_MAPS_API_KEY = "AIzaSyByEOOomzOYVpB9TaGke1SmkgM0qyNAiFA"

const defaultProps = {
  center: {
    lat: 13.0839922,
    lng: 80.2021929,
  },
  zoom: 13,
}

const mapOptions = {
  styles: [
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
        {
          color: "#f7f1df",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#d0e3b4",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry",
      stylers: [
        {
          color: "#fbd3da",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#bde6ab",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffe15f",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#efd151",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "black",
        },
      ],
    },
    {
      featureType: "transit.station.airport",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#cfb2db",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#a2daf2",
        },
      ],
    },
  ],
}

const branches = {
  annaNagar: {
    lat: 13.0839922,
    lng: 80.2021929,
  },
}

interface CustomerMarkerProps {
  lat: number
  lng: number
  text: string
}

const CustomMarker = (props: CustomerMarkerProps): JSX.Element => {
  return (
    <Box
      p={1}
      sx={{
        background: "rgba(255, 255, 255, 0.8)",
        borderRadius: "5px",
        width: "150px",
        textAlign: "center",
        fontWeight: "bold",
        position: "relative",
        left: "-70px",
      }}
    >
      {props.text}
    </Box>
  )
}

const MapSection = (): JSX.Element => {
  const renderMarkers = (map, maps) => {
    // Render the default markers
    return new maps.Marker({
      position: branches.annaNagar,
      map,
    })
  }

  return (
    <Box
      sx={{
        width: ["100%", "100%", "100%", "100%", "700px"],
        height: ["170px", "189px", "500px"],
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        options={mapOptions}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        <CustomMarker
          lat={branches.annaNagar.lat}
          lng={branches.annaNagar.lng}
          text="Sarkar IAS Academy - Anna Nagar"
        />
      </GoogleMapReact>
    </Box>
  )
}

export default MapSection
