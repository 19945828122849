import React from "react"
import { Box, Heading, Text, Grid, Flex } from "theme-ui"
import ContentSection from "../ContentSection"
import MapSection from "../Upsc/MapSection"
import { MdLocationOn } from "react-icons/md"

const ContactSection = (): JSX.Element => {
  return (
    <ContentSection
      header="Our Coaching Centers"
      body={
        <Box>
          <Grid
            sx={{ justifyItems: ["center", null, null, "start"] }}
            columns={[1, 1, 1, 1, 2]}
            gap={0}
            mt={[-4, 2]}
          >
            <MapSection />
            <Box>
              <Flex
                bg="black"
                color="gold"
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "justify",
                  paddingLeft: ["10%", "15%"],
                  paddingRight: ["10%", "15%"],
                  height: ["170px", "189px", "250px"],
                  width: ["100%", "100%", "100%", "100%", "450px"],
                }}
              >
                <Heading
                  sx={{
                    fontWeight: "600",
                    fontSize: ["18px", "24px"],
                    textAlign: "justify",
                    color: "var(--kakhi, #D6AF5C)",
                  }}
                >
                  Sarkar IAS Academy
                </Heading>
                <Text
                  sx={{ textAlign: "justify", fontSize: ["12px", "14px"] }}
                  mt={3}
                  color="white"
                >
                  It all starts here at Sarkar IAS Academy. We provide not just
                  coaching but a roadmap to success, a community of support, and
                  an unwavering belief in your potential.
                </Text>
              </Flex>
              <Flex
                bg="var(--kakhi, #D6AF5C)"
                color="black"
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "justify",
                  paddingLeft: ["10%", "15%"],
                  paddingRight: ["10%", "15%"],
                  height: ["170px", "189px", "250px"],
                  width: ["100%", "100%", "100%", "100%", "450px"],
                }}
              >
                <Flex sx={{ gap: 2 }}>
                  {/* <MdLocationOn style={{marginLeft:"-35px",marginTop:"2px"}} size={"24"} /> */}
                  <Heading
                    sx={{
                      fontWeight: "500",
                      textAlign: "justify",
                      color: "black",
                      fontWeight: "600",
                      fontSize: ["18px", "24px"],
                    }}
                  >
                    Anna Nagar
                  </Heading>
                </Flex>

                <Text
                  sx={{ textAlign: "justify", fontSize: ["12px", "14px"] }}
                  mt={3}
                >
                  New No.15,Old No.93 W-Block,
                  <br />
                  Plot No.4663,2nd Street,Anna Nagar <br /> Chennai, 600040
                </Text>
                <Text mt={3} sx={{ fontSize: ["12px", "14px"] }}>
                  Ph: +91-9962600037
                </Text>
                <Text ml={"24px"} sx={{ fontSize: ["12px", "14px"] }}>
                  +91-9962600038
                </Text>
                <Text ml={"24px"} sx={{ fontSize: ["12px", "14px"] }}>
                  +91-9962600039
                </Text>
              </Flex>
            </Box>
          </Grid>
        </Box>
      }
    />
  )
}

export default ContactSection
